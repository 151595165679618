<template>
  <v-card class="cr-modal" width="500">
    <v-card-title>
      <h2>Link To Contract</h2>
    </v-card-title>
    <v-card-text>
      <CRInput
        id="quote-form-link-to-contract-name"
        :value="contract"
        label="Contract Name"
        type="autocomplete"
        :items="suggestedContracts"
        item-text="contractName"
        item-key="quoteId"
        :return-object="true"
        :search-input.sync="contractSearchTerm"
        :clearable="true"
        clear-icon="close"
        placeholder="Enter Contract Name"
        browser-autocomplete="off"
        @input="selectContractFromList"
      ></CRInput>
    </v-card-text>
    <v-card-actions>
      <v-btn class="btn-secondaryaction" @click="close">Cancel</v-btn>
      <v-btn
        class="btn-primaryaction"
        :loading="lockSaveButton"
        @click="submit"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { filter } from '@/utils/filter'
import * as logger from '@/utils/logger'

export default {
  props: {
    quoteId: {
      type: Number,
      required: true,
    },
    existingContract: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      contract: null,
      suggestedContracts: [],
      contractSearchTerm: undefined,
      lockSaveButton: false,
    }
  },
  watch: {
    contract() {
      this.contractId = this.contract?.contractId || this.contract?.quoteId
    },
    async contractSearchTerm(value) {
      await this.searchContractsList(value)
    },
  },
  async mounted() {
    if (this.existingContract) {
      this.suggestedContracts.push(this.existingContract)
      this.contract = this.existingContract
    }
  },
  methods: {
    async searchContractsList(value) {
      const filterObject = {
        column: {
          _t_id: 'checkout_pages_search_id',
          prop: 'contractName',
          filterType: 'contains',
        },
      }
      filterObject.value = value
      if (typeof value === 'string') {
        if (value.length === 0) {
          return
        }
      } else {
        return
      }
      const contractsFilter = filter()
      const parentFilter = contractsFilter.createParent('and')
      contractsFilter.add(parentFilter, filterObject)
      const params = {
        filters: contractsFilter.asQueryParams(),
        pageSize: 5,
      }
      const matchedContractQuotes = await this.$store
        .dispatch('quotes/quotesListView', params)
        .catch((error) => {
          logger.error(error)
          this.tableProps.loading = false

          return false
        })
      this.suggestedContracts = [].concat(
        matchedContractQuotes?.data?.resultList || []
      )
    },
    selectContractFromList(contract) {
      this.contract = contract
    },
    async submit() {
      if (this.contractId) {
        this.lockSaveButton = true
        const params = {
          quoteId: this.quoteId,
          contractId: this.contractId,
        }
        try {
          await this.$store.dispatch('quotes/linkQuoteToContract', params)
          this.close()
          this.$router.push({
            name: `contracts.view`,
            params: { id: this.contractId },
          })
        } catch (err) {
          this.lockSaveButton = false
          this.$store.dispatch('app/showAlert', {
            color: 'error',
            message: 'The quote could not be linked to the contract',
          })
        }
      }
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
  },
}
</script>
